<template>
  <div v-loading="loading" element-loading-text="拼命加载中">
    <div v-if="!notask">
      <el-alert class="my-3" title="数据采集时间: " type="info" :closable="false" show-icon>{{updatedAt}}</el-alert>
      <section class="bg-white p-3 mt-3" v-if="tableData.length > 0">
        <p class="font-weight-bold text-muted mb-3">任务详情</p>
        <!-- <div class="d-flex justify-content-between mb-2">
          <section>
            <el-input v-model="search" size="medium" placeholder="输入关键字搜索" style="width:200px;">
              <i slot="suffix" class="el-input__icon el-icon-search"></i>
            </el-input>
          </section>
        </div> -->
        <el-button class="mb-3" type="primary" size="medium" icon="el-icon-download" @click="exportXls()">导出数据</el-button>
        <el-table :data="tableData" border v-if="tableData.length > 0">
          <el-table-column fixed label="ID" prop="id" min-width="180"></el-table-column>
          <el-table-column label="用户名" prop="username" min-width="150"></el-table-column>
          <el-table-column label="团队ID" prop="teamid" min-width="150"></el-table-column>
          <el-table-column v-for="(item, index) in tableData[0].items" :key="index" :label="item.name" min-width="150">
            <template slot-scope="scope">
              {{filterVal(scope.row, index)}}
            </template>
          </el-table-column>
        </el-table>
      </section>
      <!-- <el-pagination
        class="text-center py-4 mb-3"
        background
        @current-change="handleCurrentChange"
        :current-page.sync="currentPage1"
        :page-size="10"
        layout="total, prev, pager, next, jumper"
        :total="thisData.length">
      </el-pagination> -->
    </div>
    <div class="container" v-else>
      <div class="text-center">
        <img src="@/assets/img/table1.png" alt="table" style="max-width: 400px;width:100%">
        <p class="h4 text-secondary">{{tips}}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { sheet2blob, openDownloadDialog } from '@/lib/print/exportExcel'
import XLSX from 'xlsx'
import { sopDetails } from '@/api/report-task'

let vm

export default {
  name: 'table-tasks',
  components: {
  },
  data () {
    return {
      num1001: 0,
      tips: '暂无数据',
      tableData: [],
      updatedAt: null,
      loading: true,
      notask: true
    }
  },
  created () {
    vm = this
    vm.getDatas()
  },
  computed: {
    taskID () {
      const id = this.$route.query.id
      // let id = 'Q5KvJEKk001'
      return id
    },
    getTime () {
      return time => {
        const currTime = new Date(time * 1000)
        return currTime.toLocaleString()
      }
    },
    // 打印电子表格时，格式转换
    xlsTable () {
      const a = JSON.stringify(vm.tableData)
      const b = JSON.parse(a)
      const result = b.map(
        (val, index) => {
          const map = {}
          val.items.map(
            item => {
              map[item.name] = item.value
            }
          )
          delete val.items
          return Object.assign({}, val, map)
        }
      )
      return result
    }
  },
  watch: {
    $route (to, from) {
      if (to.fullPath !== from.fullPath) {
        this.getDatas()
      }
    }
  },
  methods: {
    exportXls () {
      const sheet = XLSX.utils.json_to_sheet(this.xlsTable) // 将一个table对象转换成一个sheet对象
      openDownloadDialog(sheet2blob(sheet), `${this.taskID}-任务详情.xlsx`)
    },
    // handleCurrentChange (val) {
    //   vm.currentPage1 = val
    //   vm.tableData = vm.thisData.slice((val - 1) * 10, val * 10)
    //   console.log(`当前页: ${val}`)
    // },
    filterVal (row, index) {
      if (row.items.length - 1 < index) {
        return '/'
      }
      return row.items[index].value || '/'
    },
    getDatas () {
      // console.log(this.taskID)
      this.loading = true
      sopDetails(this.taskID).then(
        res => {
          const { code, data } = res
          if (code === 0) {
            vm.tableData = data.reports || []
            vm.updatedAt = vm.getTime(data.updatedAt)
            vm.notask = false
            vm.loading = false
          } else if (code === 1001) {
            if (vm.num1001 < 2) {
              vm.num1001++
              setTimeout(() => {
                vm.getDatas()
              }, 20000) // 20s 后重试
            } else {
              vm.notask = true
              vm.tips = '数据获取中，请稍后尝试'
              vm.num1001 = 0
              vm.loading = false
            }
            // 超时，重试 2 次
          } else {
            vm.notask = true
            vm.tips = '暂无数据'
            console.log(res.message)
            vm.loading = false
          }
        }
      )
    }
  }
}
</script>

<style scoped>
</style>
